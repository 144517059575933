import { WorkspacePostInput } from '@boring.tools/schema'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Textarea,
} from '@boring.tools/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import type { z } from 'zod'
import { useWorkspaceCreate } from '../../hooks/useWorkspace'

export const WorkspaceInitialCreate = () => {
  const create = useWorkspaceCreate()
  const navigate = useNavigate()
  const form = useForm<z.infer<typeof WorkspacePostInput>>({
    resolver: zodResolver(WorkspacePostInput),
    defaultValues: {
      name: '',
      description: '',
    },
  })

  const onSubmit = (values: z.infer<typeof WorkspacePostInput>) => {
    create.mutate(values)
    navigate({ to: '/' })
  }

  return (
    <div className="w-full flex flex-col items-center mt-20">
      <div className="absolute top-10 left-10 opacity-30">
        <img src="/logo.svg" alt="Subscribe" />
        <span className="tracking-widest text-3xl font-light">
          boring.tools
        </span>
      </div>
      <div className="w-full max-w-screen-md">
        <h1 className="text-4xl">Workspace</h1>
        <p className="text-lg text-muted-foreground max-w-md">
          Create your first workspace. This serves as the basis for all our
          products.
        </p>

        <div className="flex flex-col my-10 relative">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input placeholder="My workspace" autoFocus {...field} />
                    </FormControl>
                    <FormDescription>Name of your workspace</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea placeholder="" {...field} />
                    </FormControl>
                    <FormDescription>
                      A short description about your workspace
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit">Next</Button>
            </form>
          </Form>
        </div>
      </div>
    </div>
  )
}
