import { UserPutInput } from '@boring.tools/schema'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from '@boring.tools/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import type { z } from 'zod'
import { useAuth, useUserUpdate } from '../../hooks/useAuthentication'

export const UserInitialProfile = () => {
  const auth = useAuth()
  const update = useUserUpdate()
  const form = useForm<z.infer<typeof UserPutInput>>({
    resolver: zodResolver(UserPutInput),
    defaultValues: {
      name: auth.user?.name ?? '',
    },
  })

  const onSubmit = (values: z.infer<typeof UserPutInput>) => {
    update.mutate(values)
  }

  useEffect(() => {
    form.reset({ name: auth.user?.name ?? '' })
  }, [auth.user, form])

  return (
    <div className="w-full flex flex-col items-center mt-20">
      <div className="absolute top-10 left-10 opacity-30">
        <img src="/logo.svg" alt="Subscribe" />
        <span className="tracking-widest text-3xl font-light">
          boring.tools
        </span>
      </div>

      <div className="w-full max-w-screen-md">
        <h1 className="text-4xl">Profile</h1>
        <p className="text-lg text-muted-foreground max-w-md">
          Tell us who you are.
        </p>

        <div className="flex flex-col my-10 relative">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Rick Sanchez" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit">Next</Button>
            </form>
          </Form>
        </div>
      </div>
    </div>
  )
}
