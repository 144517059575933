import { Button } from '@boring.tools/ui'
import { Link } from '@tanstack/react-router'
import { useAuth } from '../../hooks/useAuthentication'

export const BillingNotActive = () => {
  const auth = useAuth()
  if (!auth.workspace?.hasActiveSubscription) {
    return (
      <div className=" bg-rose-700 text-rose-50 absolute bottom-10 right-10 z-50 w-[500px] rounded p-3">
        <h1 className="text-xl font-bold">No active subscription</h1>
        <p>
          Your account is not active. You must buy a new subscription to be able
          to continue. Until then you only have read access to your data.
        </p>
        <Button type="button" asChild className="mt-3" size={'sm'}>
          <Link to="/workspace/subscription">Buy subscription</Link>
        </Button>
      </div>
    )
  }
  return null
}
