import { z } from '@hono/zod-openapi'

export const UserOutput = z
  .object({
    id: z.string().uuid().openapi({
      example: '123e4567-e89b-12d3-a456-426614174000',
    }),
    name: z.string().nullable(),
    email: z.string().email(),
    image: z.string().url().optional().nullable(),
    onboardingState: z
      .enum(['profile', 'workspace', 'billing'])
      .default('profile'),
    currentWorkspaceId: z.string().uuid().optional().nullable(),
  })
  .openapi('User')

export const UserSessionOutput = z.object({
  id: z.string().openapi({
    example: '74e361d9bbe5f9f5aca29c62d6a9240443b94c96f042c58844f922cea37eb7c9',
  }),
  userId: z.string().uuid(),
  expiresAt: z.date(),
})

export const MeOutput = z.object({
  user: UserOutput,
  session: UserSessionOutput,
})
