import { Card, CardContent, CardHeader, CardTitle } from '@boring.tools/ui'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, redirect } from '@tanstack/react-router'
import { Layout } from '../../components/Layout'
import { PageWrapper } from '../../components/PageWrapper'
import { WorkspaceSettingsMembersInvite } from '../../components/Workspace/Settings/Members/Invite'
import { WorkspaceSettingsMembers } from '../../components/Workspace/Settings/Members/List'
import { workspaceGetOptions } from '../../hooks/useWorkspace'

const Component = () => {
  const { data } = useSuspenseQuery(workspaceGetOptions())

  return (
    <Layout>
      <PageWrapper
        breadcrumbs={[
          {
            name: 'Workspace',
            to: '/workspace',
          },
          {
            name: data.name,
            to: '/workspace',
          },
          {
            name: 'Members',
            to: '/workspace/member',
          },
        ]}
      >
        <div className="w-full max-w-screen-md flex flex-col gap-5">
          <h1 className="text-2xl">Members</h1>
          <p>Manage your workspace members.</p>

          <Card>
            <CardHeader className="flex justify-between items-center flex-row">
              <CardTitle>Members</CardTitle>
              <WorkspaceSettingsMembersInvite />
            </CardHeader>
            <CardContent>
              <WorkspaceSettingsMembers data={data} />
            </CardContent>
          </Card>
        </div>
      </PageWrapper>
    </Layout>
  )
}

export const Route = createFileRoute('/workspace/member')({
  component: Component,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.user) {
      throw redirect({
        to: '/authentication',
        search: {
          redirect: location.href,
        },
      })
    }
  },
  loader: ({ context }) =>
    context.queryClient?.ensureQueryData(workspaceGetOptions()),
})
