import { createFileRoute, useSearch } from '@tanstack/react-router'
import { LoaderCircleIcon } from 'lucide-react'
import { useTimeout } from 'usehooks-ts'
import { z } from 'zod'
import { useGithubCallback } from '../../../../hooks/useAuthentication'

const Component = () => {
  const mutation = useGithubCallback()
  const { code, state } = useSearch({
    from: '/authentication/provider/github/callback/',
  })

  const signin = async () => {
    if (code) {
      mutation.mutate(
        { code, state },
        {
          onSettled() {
            window.location.replace('/')
          },
        },
      )
    }
  }

  useTimeout(signin, 1500)

  if (mutation.error) {
    return <div>{mutation.error.message}</div>
  }

  return (
    <div className="w-full h-screen flex items-center justify-center flex-col">
      <h1 className="text-2xl">boring.tools</h1>
      <p>Signing you in with GitHub</p>

      <div className="mt-4">
        <LoaderCircleIcon size={32} strokeWidth={1} className="animate-spin" />
      </div>
    </div>
  )
}

const schema = z.object({
  code: z.string(),
  state: z.string(),
})

export const Route = createFileRoute(
  '/authentication/provider/github/callback/',
)({
  component: Component,
  validateSearch: schema,
  errorComponent: () => <div>Required Parameters missing</div>,
})
