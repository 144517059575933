import { createFileRoute, redirect } from '@tanstack/react-router'
import { Layout } from '../components/Layout'
import { PageWrapper } from '../components/PageWrapper'
import { useAuth } from '../hooks/useAuthentication'

const Component = () => {
  const auth = useAuth()

  return (
    <Layout>
      <PageWrapper
        breadcrumbs={[
          {
            name: 'Dashboard',
            to: '/',
          },
        ]}
      >
        <h1 className="text-3xl">Welcome back, {auth.user?.name}</h1>
      </PageWrapper>
    </Layout>
  )
}
export const Route = createFileRoute('/')({
  component: Component,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.user) {
      throw redirect({
        to: '/authentication',
        search: {
          redirect: location.href,
        },
      })
    }
  },
})
