import { createFileRoute } from '@tanstack/react-router'
import { WorkspaceCreate } from '../../components/Workspace/Create'

const Component = () => {
  return <WorkspaceCreate />
}

export const Route = createFileRoute('/workspace/create')({
  component: Component,
})
