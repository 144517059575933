import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@boring.tools/ui'
import { useQuery } from '@tanstack/react-query'
import { Link, createFileRoute, redirect } from '@tanstack/react-router'
import { format } from 'date-fns'
import { Layout } from '../../components/Layout'
import { PageWrapper } from '../../components/PageWrapper'
import {
  billingPortalOptions,
  billingSubscriptionListOptions,
} from '../../hooks/useBilling'
import { Euro } from '../../utils/numbers'

const Component = () => {
  const { data, isLoading } = useQuery(billingSubscriptionListOptions)
  const portalData = useQuery(billingPortalOptions)

  return (
    <Layout>
      <PageWrapper
        breadcrumbs={[
          {
            name: 'Workspace',
            to: '/workspace',
          },
          {
            name: 'Billing',
          },
        ]}
      >
        <div className="w-full max-w-screen-md flex flex-col gap-5">
          <h1 className="text-2xl">Billing</h1>
          <p>Manage your workspace subscription and billing details.</p>

          {!portalData.isLoading && portalData.data && (
            <div>
              <Button type="button" asChild>
                <a href={portalData.data.url}>Customer Self Care Portal</a>
              </Button>
            </div>
          )}

          {isLoading && <div>Loading your subscription...</div>}

          {!isLoading && data && data.length === 0 && (
            <div>
              No active subscription.{' '}
              <Link to="/workspace/subscription" className="text-accent">
                Create new subscription
              </Link>
            </div>
          )}

          {!isLoading && data && data.length > 0 && (
            <Card>
              <CardHeader>
                <CardTitle>Subscription</CardTitle>
              </CardHeader>
              <CardContent>
                <h2 className="text-3xl">{data[0].name}</h2>
                <div>
                  {Euro.format((data[0].price * data[0].quantity) / 100)} per{' '}
                  {data[0].interval}
                </div>

                <div>SEATS {data[0].quantity}</div>

                {data[0].endsAfterPeriod && (
                  <div>
                    Subscription ends on{' '}
                    {format(new Date(data[0].endsAt), 'dd-MM-yyyy')}{' '}
                  </div>
                )}

                {/* <div className="flex mt-10 justify-between items-center">
                  {data[0].status === 'active' && !data[0].endsAfterPeriod && (
                    <BillingCancel id={data[0].id} />
                  )}
                </div> */}
              </CardContent>
            </Card>
          )}
        </div>
      </PageWrapper>
    </Layout>
  )
}

export const Route = createFileRoute('/workspace/billing')({
  component: Component,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.user) {
      throw redirect({
        to: '/authentication',
        search: {
          redirect: location.href,
        },
      })
    }
  },
})
