/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as IndexImport } from './routes/index'
import { Route as WorkspaceIndexImport } from './routes/workspace/index'
import { Route as UserIndexImport } from './routes/user/index'
import { Route as ChangelogIndexImport } from './routes/changelog/index'
import { Route as AuthenticationIndexImport } from './routes/authentication/index'
import { Route as AccessTokenIndexImport } from './routes/access-token/index'
import { Route as WorkspaceSubscriptionImport } from './routes/workspace/subscription'
import { Route as WorkspaceMemberImport } from './routes/workspace/member'
import { Route as WorkspaceInviteImport } from './routes/workspace/invite'
import { Route as WorkspaceCreateImport } from './routes/workspace/create'
import { Route as WorkspaceBillingImport } from './routes/workspace/billing'
import { Route as ChangelogCreateImport } from './routes/changelog/create'
import { Route as ChangelogIdImport } from './routes/changelog/$id'
import { Route as BillingSuccessImport } from './routes/billing/success'
import { Route as AuthenticationSignupImport } from './routes/authentication/signup'
import { Route as AccessTokenCreateImport } from './routes/access-token/create'
import { Route as ChangelogIdVersionCreateImport } from './routes/changelog_/$id/versionCreate'
import { Route as ChangelogIdEditImport } from './routes/changelog_/$id/edit'
import { Route as ChangelogIdVersionVersionIdImport } from './routes/changelog_/$id/version_/$versionId'
import { Route as AuthenticationProviderGithubCallbackIndexImport } from './routes/authentication/provider/github/callback.index'
import { Route as AuthenticationProviderEmailCallbackIndexImport } from './routes/authentication/provider/email/callback.index'

// Create Virtual Routes

const CliLazyImport = createFileRoute('/cli')()

// Create/Update Routes

const CliLazyRoute = CliLazyImport.update({
  id: '/cli',
  path: '/cli',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/cli.lazy').then((d) => d.Route))

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceIndexRoute = WorkspaceIndexImport.update({
  id: '/workspace/',
  path: '/workspace/',
  getParentRoute: () => rootRoute,
} as any)

const UserIndexRoute = UserIndexImport.update({
  id: '/user/',
  path: '/user/',
  getParentRoute: () => rootRoute,
} as any)

const ChangelogIndexRoute = ChangelogIndexImport.update({
  id: '/changelog/',
  path: '/changelog/',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticationIndexRoute = AuthenticationIndexImport.update({
  id: '/authentication/',
  path: '/authentication/',
  getParentRoute: () => rootRoute,
} as any)

const AccessTokenIndexRoute = AccessTokenIndexImport.update({
  id: '/access-token/',
  path: '/access-token/',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceSubscriptionRoute = WorkspaceSubscriptionImport.update({
  id: '/workspace/subscription',
  path: '/workspace/subscription',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceMemberRoute = WorkspaceMemberImport.update({
  id: '/workspace/member',
  path: '/workspace/member',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceInviteRoute = WorkspaceInviteImport.update({
  id: '/workspace/invite',
  path: '/workspace/invite',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceCreateRoute = WorkspaceCreateImport.update({
  id: '/workspace/create',
  path: '/workspace/create',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceBillingRoute = WorkspaceBillingImport.update({
  id: '/workspace/billing',
  path: '/workspace/billing',
  getParentRoute: () => rootRoute,
} as any)

const ChangelogCreateRoute = ChangelogCreateImport.update({
  id: '/changelog/create',
  path: '/changelog/create',
  getParentRoute: () => rootRoute,
} as any)

const ChangelogIdRoute = ChangelogIdImport.update({
  id: '/changelog/$id',
  path: '/changelog/$id',
  getParentRoute: () => rootRoute,
} as any)

const BillingSuccessRoute = BillingSuccessImport.update({
  id: '/billing/success',
  path: '/billing/success',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticationSignupRoute = AuthenticationSignupImport.update({
  id: '/authentication/signup',
  path: '/authentication/signup',
  getParentRoute: () => rootRoute,
} as any)

const AccessTokenCreateRoute = AccessTokenCreateImport.update({
  id: '/access-token/create',
  path: '/access-token/create',
  getParentRoute: () => rootRoute,
} as any)

const ChangelogIdVersionCreateRoute = ChangelogIdVersionCreateImport.update({
  id: '/changelog_/$id/versionCreate',
  path: '/changelog/$id/versionCreate',
  getParentRoute: () => rootRoute,
} as any)

const ChangelogIdEditRoute = ChangelogIdEditImport.update({
  id: '/changelog_/$id/edit',
  path: '/changelog/$id/edit',
  getParentRoute: () => rootRoute,
} as any)

const ChangelogIdVersionVersionIdRoute =
  ChangelogIdVersionVersionIdImport.update({
    id: '/changelog_/$id/version_/$versionId',
    path: '/changelog/$id/version/$versionId',
    getParentRoute: () => rootRoute,
  } as any)

const AuthenticationProviderGithubCallbackIndexRoute =
  AuthenticationProviderGithubCallbackIndexImport.update({
    id: '/authentication/provider/github/callback/',
    path: '/authentication/provider/github/callback/',
    getParentRoute: () => rootRoute,
  } as any)

const AuthenticationProviderEmailCallbackIndexRoute =
  AuthenticationProviderEmailCallbackIndexImport.update({
    id: '/authentication/provider/email/callback/',
    path: '/authentication/provider/email/callback/',
    getParentRoute: () => rootRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/cli': {
      id: '/cli'
      path: '/cli'
      fullPath: '/cli'
      preLoaderRoute: typeof CliLazyImport
      parentRoute: typeof rootRoute
    }
    '/access-token/create': {
      id: '/access-token/create'
      path: '/access-token/create'
      fullPath: '/access-token/create'
      preLoaderRoute: typeof AccessTokenCreateImport
      parentRoute: typeof rootRoute
    }
    '/authentication/signup': {
      id: '/authentication/signup'
      path: '/authentication/signup'
      fullPath: '/authentication/signup'
      preLoaderRoute: typeof AuthenticationSignupImport
      parentRoute: typeof rootRoute
    }
    '/billing/success': {
      id: '/billing/success'
      path: '/billing/success'
      fullPath: '/billing/success'
      preLoaderRoute: typeof BillingSuccessImport
      parentRoute: typeof rootRoute
    }
    '/changelog/$id': {
      id: '/changelog/$id'
      path: '/changelog/$id'
      fullPath: '/changelog/$id'
      preLoaderRoute: typeof ChangelogIdImport
      parentRoute: typeof rootRoute
    }
    '/changelog/create': {
      id: '/changelog/create'
      path: '/changelog/create'
      fullPath: '/changelog/create'
      preLoaderRoute: typeof ChangelogCreateImport
      parentRoute: typeof rootRoute
    }
    '/workspace/billing': {
      id: '/workspace/billing'
      path: '/workspace/billing'
      fullPath: '/workspace/billing'
      preLoaderRoute: typeof WorkspaceBillingImport
      parentRoute: typeof rootRoute
    }
    '/workspace/create': {
      id: '/workspace/create'
      path: '/workspace/create'
      fullPath: '/workspace/create'
      preLoaderRoute: typeof WorkspaceCreateImport
      parentRoute: typeof rootRoute
    }
    '/workspace/invite': {
      id: '/workspace/invite'
      path: '/workspace/invite'
      fullPath: '/workspace/invite'
      preLoaderRoute: typeof WorkspaceInviteImport
      parentRoute: typeof rootRoute
    }
    '/workspace/member': {
      id: '/workspace/member'
      path: '/workspace/member'
      fullPath: '/workspace/member'
      preLoaderRoute: typeof WorkspaceMemberImport
      parentRoute: typeof rootRoute
    }
    '/workspace/subscription': {
      id: '/workspace/subscription'
      path: '/workspace/subscription'
      fullPath: '/workspace/subscription'
      preLoaderRoute: typeof WorkspaceSubscriptionImport
      parentRoute: typeof rootRoute
    }
    '/access-token/': {
      id: '/access-token/'
      path: '/access-token'
      fullPath: '/access-token'
      preLoaderRoute: typeof AccessTokenIndexImport
      parentRoute: typeof rootRoute
    }
    '/authentication/': {
      id: '/authentication/'
      path: '/authentication'
      fullPath: '/authentication'
      preLoaderRoute: typeof AuthenticationIndexImport
      parentRoute: typeof rootRoute
    }
    '/changelog/': {
      id: '/changelog/'
      path: '/changelog'
      fullPath: '/changelog'
      preLoaderRoute: typeof ChangelogIndexImport
      parentRoute: typeof rootRoute
    }
    '/user/': {
      id: '/user/'
      path: '/user'
      fullPath: '/user'
      preLoaderRoute: typeof UserIndexImport
      parentRoute: typeof rootRoute
    }
    '/workspace/': {
      id: '/workspace/'
      path: '/workspace'
      fullPath: '/workspace'
      preLoaderRoute: typeof WorkspaceIndexImport
      parentRoute: typeof rootRoute
    }
    '/changelog_/$id/edit': {
      id: '/changelog_/$id/edit'
      path: '/changelog/$id/edit'
      fullPath: '/changelog/$id/edit'
      preLoaderRoute: typeof ChangelogIdEditImport
      parentRoute: typeof rootRoute
    }
    '/changelog_/$id/versionCreate': {
      id: '/changelog_/$id/versionCreate'
      path: '/changelog/$id/versionCreate'
      fullPath: '/changelog/$id/versionCreate'
      preLoaderRoute: typeof ChangelogIdVersionCreateImport
      parentRoute: typeof rootRoute
    }
    '/changelog_/$id/version_/$versionId': {
      id: '/changelog_/$id/version_/$versionId'
      path: '/changelog/$id/version/$versionId'
      fullPath: '/changelog/$id/version/$versionId'
      preLoaderRoute: typeof ChangelogIdVersionVersionIdImport
      parentRoute: typeof rootRoute
    }
    '/authentication/provider/email/callback/': {
      id: '/authentication/provider/email/callback/'
      path: '/authentication/provider/email/callback'
      fullPath: '/authentication/provider/email/callback'
      preLoaderRoute: typeof AuthenticationProviderEmailCallbackIndexImport
      parentRoute: typeof rootRoute
    }
    '/authentication/provider/github/callback/': {
      id: '/authentication/provider/github/callback/'
      path: '/authentication/provider/github/callback'
      fullPath: '/authentication/provider/github/callback'
      preLoaderRoute: typeof AuthenticationProviderGithubCallbackIndexImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/cli': typeof CliLazyRoute
  '/access-token/create': typeof AccessTokenCreateRoute
  '/authentication/signup': typeof AuthenticationSignupRoute
  '/billing/success': typeof BillingSuccessRoute
  '/changelog/$id': typeof ChangelogIdRoute
  '/changelog/create': typeof ChangelogCreateRoute
  '/workspace/billing': typeof WorkspaceBillingRoute
  '/workspace/create': typeof WorkspaceCreateRoute
  '/workspace/invite': typeof WorkspaceInviteRoute
  '/workspace/member': typeof WorkspaceMemberRoute
  '/workspace/subscription': typeof WorkspaceSubscriptionRoute
  '/access-token': typeof AccessTokenIndexRoute
  '/authentication': typeof AuthenticationIndexRoute
  '/changelog': typeof ChangelogIndexRoute
  '/user': typeof UserIndexRoute
  '/workspace': typeof WorkspaceIndexRoute
  '/changelog/$id/edit': typeof ChangelogIdEditRoute
  '/changelog/$id/versionCreate': typeof ChangelogIdVersionCreateRoute
  '/changelog/$id/version/$versionId': typeof ChangelogIdVersionVersionIdRoute
  '/authentication/provider/email/callback': typeof AuthenticationProviderEmailCallbackIndexRoute
  '/authentication/provider/github/callback': typeof AuthenticationProviderGithubCallbackIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/cli': typeof CliLazyRoute
  '/access-token/create': typeof AccessTokenCreateRoute
  '/authentication/signup': typeof AuthenticationSignupRoute
  '/billing/success': typeof BillingSuccessRoute
  '/changelog/$id': typeof ChangelogIdRoute
  '/changelog/create': typeof ChangelogCreateRoute
  '/workspace/billing': typeof WorkspaceBillingRoute
  '/workspace/create': typeof WorkspaceCreateRoute
  '/workspace/invite': typeof WorkspaceInviteRoute
  '/workspace/member': typeof WorkspaceMemberRoute
  '/workspace/subscription': typeof WorkspaceSubscriptionRoute
  '/access-token': typeof AccessTokenIndexRoute
  '/authentication': typeof AuthenticationIndexRoute
  '/changelog': typeof ChangelogIndexRoute
  '/user': typeof UserIndexRoute
  '/workspace': typeof WorkspaceIndexRoute
  '/changelog/$id/edit': typeof ChangelogIdEditRoute
  '/changelog/$id/versionCreate': typeof ChangelogIdVersionCreateRoute
  '/changelog/$id/version/$versionId': typeof ChangelogIdVersionVersionIdRoute
  '/authentication/provider/email/callback': typeof AuthenticationProviderEmailCallbackIndexRoute
  '/authentication/provider/github/callback': typeof AuthenticationProviderGithubCallbackIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/cli': typeof CliLazyRoute
  '/access-token/create': typeof AccessTokenCreateRoute
  '/authentication/signup': typeof AuthenticationSignupRoute
  '/billing/success': typeof BillingSuccessRoute
  '/changelog/$id': typeof ChangelogIdRoute
  '/changelog/create': typeof ChangelogCreateRoute
  '/workspace/billing': typeof WorkspaceBillingRoute
  '/workspace/create': typeof WorkspaceCreateRoute
  '/workspace/invite': typeof WorkspaceInviteRoute
  '/workspace/member': typeof WorkspaceMemberRoute
  '/workspace/subscription': typeof WorkspaceSubscriptionRoute
  '/access-token/': typeof AccessTokenIndexRoute
  '/authentication/': typeof AuthenticationIndexRoute
  '/changelog/': typeof ChangelogIndexRoute
  '/user/': typeof UserIndexRoute
  '/workspace/': typeof WorkspaceIndexRoute
  '/changelog_/$id/edit': typeof ChangelogIdEditRoute
  '/changelog_/$id/versionCreate': typeof ChangelogIdVersionCreateRoute
  '/changelog_/$id/version_/$versionId': typeof ChangelogIdVersionVersionIdRoute
  '/authentication/provider/email/callback/': typeof AuthenticationProviderEmailCallbackIndexRoute
  '/authentication/provider/github/callback/': typeof AuthenticationProviderGithubCallbackIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/cli'
    | '/access-token/create'
    | '/authentication/signup'
    | '/billing/success'
    | '/changelog/$id'
    | '/changelog/create'
    | '/workspace/billing'
    | '/workspace/create'
    | '/workspace/invite'
    | '/workspace/member'
    | '/workspace/subscription'
    | '/access-token'
    | '/authentication'
    | '/changelog'
    | '/user'
    | '/workspace'
    | '/changelog/$id/edit'
    | '/changelog/$id/versionCreate'
    | '/changelog/$id/version/$versionId'
    | '/authentication/provider/email/callback'
    | '/authentication/provider/github/callback'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/cli'
    | '/access-token/create'
    | '/authentication/signup'
    | '/billing/success'
    | '/changelog/$id'
    | '/changelog/create'
    | '/workspace/billing'
    | '/workspace/create'
    | '/workspace/invite'
    | '/workspace/member'
    | '/workspace/subscription'
    | '/access-token'
    | '/authentication'
    | '/changelog'
    | '/user'
    | '/workspace'
    | '/changelog/$id/edit'
    | '/changelog/$id/versionCreate'
    | '/changelog/$id/version/$versionId'
    | '/authentication/provider/email/callback'
    | '/authentication/provider/github/callback'
  id:
    | '__root__'
    | '/'
    | '/cli'
    | '/access-token/create'
    | '/authentication/signup'
    | '/billing/success'
    | '/changelog/$id'
    | '/changelog/create'
    | '/workspace/billing'
    | '/workspace/create'
    | '/workspace/invite'
    | '/workspace/member'
    | '/workspace/subscription'
    | '/access-token/'
    | '/authentication/'
    | '/changelog/'
    | '/user/'
    | '/workspace/'
    | '/changelog_/$id/edit'
    | '/changelog_/$id/versionCreate'
    | '/changelog_/$id/version_/$versionId'
    | '/authentication/provider/email/callback/'
    | '/authentication/provider/github/callback/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  CliLazyRoute: typeof CliLazyRoute
  AccessTokenCreateRoute: typeof AccessTokenCreateRoute
  AuthenticationSignupRoute: typeof AuthenticationSignupRoute
  BillingSuccessRoute: typeof BillingSuccessRoute
  ChangelogIdRoute: typeof ChangelogIdRoute
  ChangelogCreateRoute: typeof ChangelogCreateRoute
  WorkspaceBillingRoute: typeof WorkspaceBillingRoute
  WorkspaceCreateRoute: typeof WorkspaceCreateRoute
  WorkspaceInviteRoute: typeof WorkspaceInviteRoute
  WorkspaceMemberRoute: typeof WorkspaceMemberRoute
  WorkspaceSubscriptionRoute: typeof WorkspaceSubscriptionRoute
  AccessTokenIndexRoute: typeof AccessTokenIndexRoute
  AuthenticationIndexRoute: typeof AuthenticationIndexRoute
  ChangelogIndexRoute: typeof ChangelogIndexRoute
  UserIndexRoute: typeof UserIndexRoute
  WorkspaceIndexRoute: typeof WorkspaceIndexRoute
  ChangelogIdEditRoute: typeof ChangelogIdEditRoute
  ChangelogIdVersionCreateRoute: typeof ChangelogIdVersionCreateRoute
  ChangelogIdVersionVersionIdRoute: typeof ChangelogIdVersionVersionIdRoute
  AuthenticationProviderEmailCallbackIndexRoute: typeof AuthenticationProviderEmailCallbackIndexRoute
  AuthenticationProviderGithubCallbackIndexRoute: typeof AuthenticationProviderGithubCallbackIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  CliLazyRoute: CliLazyRoute,
  AccessTokenCreateRoute: AccessTokenCreateRoute,
  AuthenticationSignupRoute: AuthenticationSignupRoute,
  BillingSuccessRoute: BillingSuccessRoute,
  ChangelogIdRoute: ChangelogIdRoute,
  ChangelogCreateRoute: ChangelogCreateRoute,
  WorkspaceBillingRoute: WorkspaceBillingRoute,
  WorkspaceCreateRoute: WorkspaceCreateRoute,
  WorkspaceInviteRoute: WorkspaceInviteRoute,
  WorkspaceMemberRoute: WorkspaceMemberRoute,
  WorkspaceSubscriptionRoute: WorkspaceSubscriptionRoute,
  AccessTokenIndexRoute: AccessTokenIndexRoute,
  AuthenticationIndexRoute: AuthenticationIndexRoute,
  ChangelogIndexRoute: ChangelogIndexRoute,
  UserIndexRoute: UserIndexRoute,
  WorkspaceIndexRoute: WorkspaceIndexRoute,
  ChangelogIdEditRoute: ChangelogIdEditRoute,
  ChangelogIdVersionCreateRoute: ChangelogIdVersionCreateRoute,
  ChangelogIdVersionVersionIdRoute: ChangelogIdVersionVersionIdRoute,
  AuthenticationProviderEmailCallbackIndexRoute:
    AuthenticationProviderEmailCallbackIndexRoute,
  AuthenticationProviderGithubCallbackIndexRoute:
    AuthenticationProviderGithubCallbackIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/cli",
        "/access-token/create",
        "/authentication/signup",
        "/billing/success",
        "/changelog/$id",
        "/changelog/create",
        "/workspace/billing",
        "/workspace/create",
        "/workspace/invite",
        "/workspace/member",
        "/workspace/subscription",
        "/access-token/",
        "/authentication/",
        "/changelog/",
        "/user/",
        "/workspace/",
        "/changelog_/$id/edit",
        "/changelog_/$id/versionCreate",
        "/changelog_/$id/version_/$versionId",
        "/authentication/provider/email/callback/",
        "/authentication/provider/github/callback/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/cli": {
      "filePath": "cli.lazy.tsx"
    },
    "/access-token/create": {
      "filePath": "access-token/create.tsx"
    },
    "/authentication/signup": {
      "filePath": "authentication/signup.tsx"
    },
    "/billing/success": {
      "filePath": "billing/success.tsx"
    },
    "/changelog/$id": {
      "filePath": "changelog/$id.tsx"
    },
    "/changelog/create": {
      "filePath": "changelog/create.tsx"
    },
    "/workspace/billing": {
      "filePath": "workspace/billing.tsx"
    },
    "/workspace/create": {
      "filePath": "workspace/create.tsx"
    },
    "/workspace/invite": {
      "filePath": "workspace/invite.tsx"
    },
    "/workspace/member": {
      "filePath": "workspace/member.tsx"
    },
    "/workspace/subscription": {
      "filePath": "workspace/subscription.tsx"
    },
    "/access-token/": {
      "filePath": "access-token/index.tsx"
    },
    "/authentication/": {
      "filePath": "authentication/index.tsx"
    },
    "/changelog/": {
      "filePath": "changelog/index.tsx"
    },
    "/user/": {
      "filePath": "user/index.tsx"
    },
    "/workspace/": {
      "filePath": "workspace/index.tsx"
    },
    "/changelog_/$id/edit": {
      "filePath": "changelog_/$id/edit.tsx"
    },
    "/changelog_/$id/versionCreate": {
      "filePath": "changelog_/$id/versionCreate.tsx"
    },
    "/changelog_/$id/version_/$versionId": {
      "filePath": "changelog_/$id/version_/$versionId.tsx"
    },
    "/authentication/provider/email/callback/": {
      "filePath": "authentication/provider/email/callback.index.tsx"
    },
    "/authentication/provider/github/callback/": {
      "filePath": "authentication/provider/github/callback.index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
