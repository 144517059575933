import { z } from '@hono/zod-openapi'

export const WorkspaceMemberInviteInput = z
  .object({
    emails: z.string().openapi({ example: 'one@example.com, two@example.com' }),
  })
  .openapi('WorkspaceMemberInviteInput')

export const WorkspaceMemberInviteOutput = z
  .object({
    email: z.string().email(),
    expiresAt: z.string().or(z.date()),
  })
  .openapi('WorkspaceMemberInviteOutput')
