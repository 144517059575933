import { z } from '@hono/zod-openapi'

const checkFileType = (file: File) => {
  const fileTypes = ['png', 'jpg', 'jpeg', 'svg']
  if (file?.name) {
    const fileType = file.name.split('.').pop()
    if (!fileType) return false
    if (fileTypes.includes(fileType)) return true
  }
  return false
}

function imageSize(image: File): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader()

      fileReader.onload = () => {
        const img = new Image()

        img.onload = () => {
          resolve({ width: img.width, height: img.height })
        }

        img.src = fileReader.result as string
      }

      fileReader.readAsDataURL(image)
    } catch (e) {
      reject(e)
    }
  })
}

export const WorkspaceLogoInput = z
  .object({
    file: z
      .any()
      .refine((file: File) => file?.length !== 0, 'File is required')
      .refine(
        (file: File[]) => checkFileType(file[0]),
        'Only .png, .jpg, .jpeg, .svg formats are supported.',
      )
      .refine(async (file: File[]) => {
        const { width, height } = await imageSize(file[0])
        if (width < 500 && height < 500) return true
        return false
      }, "Image's width and height should be less than 500px"),
    // .refine((file) => file.size < MAX_FILE_SIZE, 'Max size is 5MB.'),
  })
  .openapi({
    title: 'WorkspaceLogoInput',
    description: 'Input for uploading a workspace logo',
  })
