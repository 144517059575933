import { z } from '@hono/zod-openapi'

export const AuthenticationEmailRequestInput = z.object({
  email: z.string().email(),
})

export const AuthenticationEmailCallbackInput = z.object({
  email: z.string().email(),
  token: z.string(),
})

export const AuthenticationEmailSignupInput = z.object({
  email: z.string().email(),
  name: z.string(),
  tos: z.boolean(),
})
