import { createFileRoute } from '@tanstack/react-router'
import { Layout } from '../../components/Layout'
import { PageWrapper } from '../../components/PageWrapper'
import { UserProfile } from '../../components/User/Profile'

const Component = () => {
  return (
    <Layout>
      <PageWrapper
        breadcrumbs={[
          {
            name: 'User',
            to: '/',
          },
        ]}
      >
        <h1 className="text-2xl">User</h1>
        <UserProfile />
      </PageWrapper>
    </Layout>
  )
}

export const Route = createFileRoute('/user/')({
  component: Component,
})
