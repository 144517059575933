import { createFileRoute } from '@tanstack/react-router'
import { BillingSuccess } from '../../components/Billing/Success'

const Component = () => {
  return <BillingSuccess />
}

export const Route = createFileRoute('/billing/success')({
  component: Component,
})
