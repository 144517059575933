import { createFileRoute, useNavigate, useSearch } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { useTimeout } from 'usehooks-ts'
import { z } from 'zod'
import { AuthenticationOTP } from '../../components/Authentication/OTP'
import { AuthenticationSignin } from '../../components/Authentication/Signin'
import { useAuth } from '../../hooks/useAuthentication'

const Component = () => {
  const [waiting, setWaiting] = useState(true)
  const auth = useAuth()
  const search = useSearch({ from: '/authentication/' })
  const navigate = useNavigate()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (auth.user) {
      navigate({ to: search.redirect, mask: { to: search.redirect } })
    }
  }, [auth])

  useTimeout(() => setWaiting(false), 500)

  if (waiting) {
    return null
  }

  return (
    <div className="w-full h-screen items-center justify-center flex flex-col">
      <div className="flex items-baseline gap-2">
        <img src="/logo.svg" alt="boring.tools" className="w-10 h-10" />
        <h1 className="text-2xl">boring.tools</h1>
      </div>
      {search.email && <AuthenticationOTP email={search.email} />}

      {!search.email && <AuthenticationSignin />}
    </div>
  )
}

export const Route = createFileRoute('/authentication/')({
  component: Component,
  validateSearch: z.object({
    redirect: z.string().optional().catch(''),
    email: z.string().email().optional(),
  }),
})
