import { z } from '@hono/zod-openapi'

export const WorkspaceOutput = z
  .object({
    id: z.string().uuid().openapi({
      example: '123e4567-e89b-12d3-a456-426614174000',
    }),
    name: z.string().openapi({
      title: 'Workspace Name',
      example: 'My Workspace',
    }),
    description: z.string().optional().openapi({
      title: 'Workspace Description',
      example: 'A description of my workspace',
    }),
    logo: z.string().optional().nullable().openapi({
      title: 'Workspace Logo',
      example: 'https://example.com/logo.png',
    }),
    hasActiveSubscription: z.boolean().openapi({
      title: 'Has Active Subscription',
      example: true,
    }),
    members: z
      .array(
        z.object({
          id: z.string().uuid(),
          name: z.string(),
          email: z.string().email(),
          image: z.string().url().optional().nullable(),
          memberId: z.string().uuid(),
        }),
      )
      .optional(),
  })
  .openapi({
    title: 'Workspace',
    description: 'A workspace is a collection of related resources',
  })
