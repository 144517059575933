import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Textarea,
} from '@boring.tools/ui'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { WorkspacePostInput } from '@boring.tools/schema'
import type { z } from 'zod'
import { useWorkspaceCreate } from '../../hooks/useWorkspace'
import { useLocation, useNavigate, useRouter } from '@tanstack/react-router'
import { ArrowLeftIcon } from 'lucide-react'

export const WorkspaceCreate = () => {
  const create = useWorkspaceCreate()
  const location = useLocation()
  const router = useRouter()
  const navigate = useNavigate()
  const form = useForm<z.infer<typeof WorkspacePostInput>>({
    resolver: zodResolver(WorkspacePostInput),
    defaultValues: {
      name: '',
      description: '',
    },
  })

  const onSubmit = (values: z.infer<typeof WorkspacePostInput>) => {
    create.mutate(values)
    navigate({ to: '/' })
  }

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="w-full max-w-md">
        {location.pathname === '/workspace/create' && (
          <button
            onClick={() => router.history.back()}
            type="button"
            className="flex gap-1 text-muted-foreground items-center w-full justify-start mb-3 hover:text-primary transition"
          >
            <ArrowLeftIcon className="w-4 h-4" />
            Back
          </button>
        )}
        <Card className="w-full max-w-md">
          <CardHeader>
            <CardTitle>Create a workspace</CardTitle>
          </CardHeader>
          <CardContent>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-8"
              >
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="My workspace"
                          autoFocus
                          {...field}
                        />
                      </FormControl>
                      <FormDescription>Name of your workspace</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Description</FormLabel>
                      <FormControl>
                        <Textarea placeholder="" {...field} />
                      </FormControl>
                      <FormDescription>
                        A short description about your workspace
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button type="submit">Create</Button>
              </form>
            </Form>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}
