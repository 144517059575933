import { z } from '@hono/zod-openapi'
import { EmailInputBase } from './base'

export const EmailSigninInput = EmailInputBase.extend({
  payload: z.object({
    token: z.string(),
    email: z.string().email(),
    expiresAt: z.date().or(z.string()),
  }),
})
