import { z } from '@hono/zod-openapi'

export const WorkspacePostInput = z
  .object({
    name: z.string().openapi({
      title: 'Workspace Name',
      example: 'My Workspace',
    }),
    description: z.string().optional().openapi({
      title: 'Workspace Description',
      example: 'My personal workspace',
    }),
  })
  .openapi({
    title: 'WorkspacePostInput',
    description: 'Input for creating a workspace',
  })
