import { env } from '@boring.tools/env'
import type {
  AuthenticationEmailCallbackInput,
  AuthenticationEmailRequestInput,
  AuthenticationEmailSignupInput,
  UserOutput,
  UserPutInput,
  UserSessionOutput,
  WorkspaceOutput,
} from '@boring.tools/schema'
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { createContext, useContext } from 'react'
import type { z } from 'zod'
import { queryFetch } from '../utils/queryFetch'

export const authWithGithub = async () => {
  try {
    const result = await fetch(
      `${env.VITE_BACKEND_URL}/v1/authentication/provider/github`,
    )

    if (!result.ok) {
      throw new Error('Failed to authenticate with GitHub')
    }
    const body = await result.json()
    window.location.replace(body.url)
  } catch (error) {
    console.error(error)
  }
}

export const useGithubCallback = () => {
  return useMutation({
    mutationFn: async (payload: { code: string; state: string }) => {
      return await queryFetch({
        method: 'post',
        path: 'authentication/provider/github/callback',
        data: payload,
      })
    },
  })
}

export const userQueryOptions = queryOptions({
  queryKey: ['user'],
  queryFn: async () => {
    return await queryFetch({
      method: 'get',
      path: 'authentication/me',
    })
  },
})

export const useUser = () => {
  return useQuery(userQueryOptions)
}

export const useAuthentication = () => {
  return {
    authWithGithub,
  }
}

export interface AuthContext {
  user: z.infer<typeof UserOutput> | null
  session: z.infer<typeof UserSessionOutput> | null
  workspace: z.infer<typeof WorkspaceOutput> | null
}
const AuthContext = createContext<AuthContext | null>(null)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { data } = useUser()

  return (
    <AuthContext.Provider value={{ ...data }}>{children}</AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export const useSignout = () => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async () => {
      return await queryFetch({
        method: 'delete',
        path: 'user/session',
      })
    },
    onSuccess: () => {
      navigate({ to: '/authentication' })
    },
  })
}

export const useUserUpdate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (payload: z.infer<typeof UserPutInput>) => {
      return await queryFetch({
        method: 'put',
        path: 'user',
        data: payload,
      })
    },
    onSuccess: async () => {
      queryClient.invalidateQueries()
    },
  })
}

export const useAuthenticationEmailRequest = () => {
  return useMutation({
    mutationFn: async (
      payload: z.infer<typeof AuthenticationEmailRequestInput>,
    ) => {
      return await queryFetch({
        method: 'post',
        path: 'authentication/provider/email',
        data: payload,
      })
    },
  })
}

export const useAuthenticationEmailCallback = () => {
  return useMutation({
    mutationFn: async (
      payload: z.infer<typeof AuthenticationEmailCallbackInput>,
    ) => {
      return await queryFetch({
        method: 'post',
        path: 'authentication/provider/email/callback',
        data: payload,
      })
    },
  })
}

export const useAuthenticationEmailSignup = () => {
  return useMutation({
    mutationFn: async (
      payload: z.infer<typeof AuthenticationEmailSignupInput>,
    ) => {
      return await queryFetch({
        method: 'post',
        path: 'authentication/provider/email/signup',
        data: payload,
      })
    },
  })
}
