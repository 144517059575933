import { env } from '@boring.tools/env'
import axios, { AxiosError } from 'axios'

type Fetch = {
  path: string
  method: 'get' | 'post' | 'put' | 'delete'
  data?: unknown
}

const url = env.VITE_BACKEND_URL

export const queryFetch = async ({ path, method, data }: Fetch) => {
  try {
    const response = await axios({
      method,
      url: `${url}/v1/${path}`,
      data,
      withCredentials: true,
    })
    return response.data
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response) {
        throw new Error(error.response.data.message)
      }
    }
    throw new Error('Something went wrong.')
  }
}
