import { SidebarInset } from '@boring.tools/ui'
import type { ReactNode } from 'react'
import { BillingNotActive } from './Billing/NotActive'
import { Sidebar } from './Sidebar'

export const Layout = ({ children }: { children: ReactNode | ReactNode[] }) => {
  return (
    <>
      <BillingNotActive />
      <Sidebar />
      <SidebarInset>{children}</SidebarInset>
    </>
  )
}
