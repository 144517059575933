import { Toaster } from '@boring.tools/ui'
import { Outlet } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'

export const LayoutPublic = () => {
  return (
    <>
      <Outlet />
      <Toaster />
      {!import.meta.env.PROD && (
        <TanStackRouterDevtools position="bottom-right" />
      )}
    </>
  )
}
