import { z } from '@hono/zod-openapi'

export const WorkspaceMemberInviteGetParams = z.object({
  email: z.string(),
  token: z.string(),
})

export const WorkspaceMemberInviteAcceptInput = z.object({
  email: z.string().email(),
  token: z.string(),
  accepted: z.boolean(),
})

export const WorkspaceMemberInviteDeleteParams = z.object({
  email: z.string().email(),
})

export const WorkspaceMemberInviteGetOutput = z.object({
  name: z.string(),
  logo: z.string().url().optional(),
})
