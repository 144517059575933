import { createFileRoute } from '@tanstack/react-router'
import { BillingSubscribe } from '../../components/Billing/Subscribe'

const Component = () => {
  return <BillingSubscribe />
}

export const Route = createFileRoute('/workspace/subscription')({
  component: Component,
})
