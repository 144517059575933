import { createEnv } from '@t3-oss/env-core'
import { z } from 'zod'

export const env = createEnv({
  server: {
    POSTGRES_URL: z.string(),
    REDIS_PASSWORD: z.string(),
    REDIS_URL: z.string(),

    NODE_ENV: z.string(),

    SERVICE_NAME: z.string(),
    FRONTEND_URL: z.string().url().min(1),

    GITHUB_CLIENT_ID: z.string(),
    GITHUB_CLIENT_SECRET: z.string(),
    GITHUB_REDIRECT_URL: z.string(),

    EMAIL_HOST: z.string(),
    EMAIL_PORT: z.number().or(z.string()),
    EMAIL_SECURE: z.boolean().or(z.string()),
    EMAIL_USER: z.string(),
    EMAIL_PASSWORD: z.string(),
    EMAIL_FROM: z.string().email(),

    LOKI_URL: z.string().url().min(1),

    S3_REGION: z.string(),
    S3_URL: z.string(),
    S3_ACCESS_KEY_ID: z.string(),
    S3_ACCESS_KEY_SECRET: z.string(),

    STRIPE_PUBLIC_KEY: z.string(),
    STRIPE_SECRET_KEY: z.string(),
    STRIPE_WEBHOOK_SECRET: z.string(),
  },

  /**
   * The prefix that client-side variables must have. This is enforced both at
   * a type-level and at runtime.
   */
  clientPrefix: 'VITE_',

  client: {
    VITE_BACKEND_URL: z.string().url().min(1),
    VITE_FRONTEND_URL: z.string().url().min(1),
    VITE_ENV: z.enum(['development', 'production', 'test']),
  },

  /**
   * What object holds the environment variables at runtime. This is usually
   * `process.env` or `import.meta.env`.
   */
  runtimeEnv: import.meta.env,

  /**
   * By default, this library will feed the environment variables directly to
   * the Zod validator.
   *
   * This means that if you have an empty string for a value that is supposed
   * to be a number (e.g. `PORT=` in a ".env" file), Zod will incorrectly flag
   * it as a type mismatch violation. Additionally, if you have an empty string
   * for a value that is supposed to be a string with a default value (e.g.
   * `DOMAIN=` in an ".env" file), the default value will never be applied.
   *
   * In order to solve these issues, we recommend that all new projects
   * explicitly specify this option as true.
   */
  emptyStringAsUndefined: true,
  isServer: typeof window === 'undefined',
})
