import { ChangelogUpdateInput } from '@boring.tools/schema'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Checkbox,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Textarea,
} from '@boring.tools/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, redirect, useNavigate } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import type { z } from 'zod'
import { Layout } from '../../../components/Layout'
import { PageWrapper } from '../../../components/PageWrapper'
import {
  changelogByIdOptions,
  useChangelogUpdate,
} from '../../../hooks/useChangelog'

const Component = () => {
  const { id } = Route.useParams()
  const { data, isPending } = useSuspenseQuery(changelogByIdOptions(id))
  const navigate = useNavigate({ from: '/changelog/$id/edit' })
  const changelogCreate = useChangelogUpdate()
  const form = useForm<z.infer<typeof ChangelogUpdateInput>>({
    resolver: zodResolver(ChangelogUpdateInput),
    defaultValues: data,
  })

  const onSubmit = (payload: z.infer<typeof ChangelogUpdateInput>) => {
    changelogCreate.mutate(
      { id, payload },
      {
        onSuccess(data) {
          navigate({ to: '/changelog/$id', params: { id: data.id } })
        },
      },
    )
  }

  return (
    <Layout>
      <PageWrapper
        breadcrumbs={[
          { name: 'Changelog', to: '/changelog' },
          { name: data?.title, to: `/changelog/${data?.id}` },
        ]}
      >
        <div className="flex flex-col gap-5">
          {!isPending && data && (
            <div>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="flex flex-col gap-10 w-full max-w-screen-lg"
                >
                  <div className="flex gap-10 w-full max-w-screen-lg">
                    <Card className="w-full">
                      <CardHeader>
                        <CardTitle>Details</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <div className="flex flex-col gap-3 w-full">
                          <FormField
                            control={form.control}
                            name="title"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Title</FormLabel>
                                <FormControl>
                                  <Input
                                    placeholder="My changelog"
                                    {...field}
                                    autoFocus
                                  />
                                </FormControl>{' '}
                                <FormMessage />
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name="description"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Description</FormLabel>
                                <FormControl>
                                  <Textarea
                                    placeholder="Some details about the changelog..."
                                    {...field}
                                  />
                                </FormControl>{' '}
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                      </CardContent>
                    </Card>

                    <Card className="w-full">
                      <CardHeader>
                        <CardTitle>Options</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <div className="w-full flex flex-col gap-5">
                          <FormField
                            control={form.control}
                            name="isSemver"
                            render={({ field }) => (
                              <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md ">
                                <FormControl>
                                  <Checkbox
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                                <div className="space-y-1 leading-none">
                                  <FormLabel>Using Semver</FormLabel>
                                  <FormDescription>
                                    If this changelog is following the{' '}
                                    <a
                                      href="https://semver.org/lang/de/"
                                      className="text-emerald-700"
                                    >
                                      semantic versioning?
                                    </a>
                                  </FormDescription>
                                </div>
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name="isConventional"
                            render={({ field }) => (
                              <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md ">
                                <FormControl>
                                  <Checkbox
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                                <div className="space-y-1 leading-none">
                                  <FormLabel>
                                    Using Conventional Commits
                                  </FormLabel>
                                  <FormDescription>
                                    If this changelog is using{' '}
                                    <a
                                      href="https://www.conventionalcommits.org/en/v1.0.0/"
                                      className="text-emerald-700"
                                    >
                                      conventional commits
                                    </a>
                                  </FormDescription>
                                </div>
                              </FormItem>
                            )}
                          />
                        </div>
                      </CardContent>
                    </Card>
                  </div>

                  <div className="flex items-end justify-end gap-5">
                    <Button
                      type="button"
                      variant={'ghost'}
                      onClick={() =>
                        navigate({ to: '/changelog/$id', params: { id } })
                      }
                    >
                      Cancel
                    </Button>

                    <Button type="submit">Save</Button>
                  </div>
                </form>
              </Form>
            </div>
          )}
        </div>
      </PageWrapper>
    </Layout>
  )
}

export const Route = createFileRoute('/changelog_/$id/edit')({
  component: Component,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.user) {
      throw redirect({
        to: '/authentication',
        search: {
          redirect: location.href,
        },
      })
    }
  },
  loader: ({ context, params }) => {
    return context.queryClient?.ensureQueryData(changelogByIdOptions(params.id))
  },
})
