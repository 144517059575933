import type {
  BillingStripeSessionInput,
  BillingStripeSessionOutput,
} from '@boring.tools/schema'
import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import type { z } from 'zod'
import { queryFetch } from '../utils/queryFetch'

type SessionInput = z.infer<typeof BillingStripeSessionInput>
type SessionOutput = z.infer<typeof BillingStripeSessionOutput>

export const billingSubscriptionListOptions = queryOptions({
  queryKey: ['billing'],
  queryFn: async () =>
    await queryFetch({
      method: 'get',
      path: 'billing/subscription',
    }),
})

export const billingPortalOptions = queryOptions({
  queryKey: ['billing', 'portal'],
  queryFn: async () =>
    await queryFetch({
      method: 'get',
      path: 'billing/portal',
    }),
})

export const useBillingSession = () => {
  return useMutation({
    mutationFn: async (
      payload: SessionInput,
    ): Promise<Readonly<SessionOutput>> =>
      await queryFetch({
        path: 'billing/session',
        data: payload,
        method: 'post',
      }),
  })
}

export const useBillingSubscriptionCancel = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id: string): Promise<Readonly<null>> =>
      await queryFetch({
        path: `billing/subscription/${id}`,
        method: 'delete',
      }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['billing'] })
    },
  })
}
