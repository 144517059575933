import { z } from '@hono/zod-openapi'
import { EmailInputBase } from './base'

export const EmailWorkspaceInput = EmailInputBase.extend({
  payload: z.object({
    workspace: z.object({
      name: z.string(),
    }),
    invite: z.object({
      token: z.string(),
      email: z.string().email(),
    }),
  }),
})
