import { z } from '@hono/zod-openapi'

export const WorkspacePutParams = z
  .object({
    id: z.string().openapi({
      title: 'Workspace ID',
      example: '123e4567-e89b-12d3-a456-426614174000',
      description: 'The ID of the workspace to update',
    }),
  })
  .openapi('Workspace')

export const WorkspacePutInput = z
  .object({
    name: z.string().openapi({
      title: 'Workspace Name',
      example: 'My Workspace',
    }),
    description: z.string().optional().openapi({
      title: 'Workspace Description',
      example: 'A description of my workspace',
    }),
  })
  .openapi('Workspace')
