import { WorkspaceMemberInviteInput } from '@boring.tools/schema'
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  toast,
} from '@boring.tools/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import type { z } from 'zod'
import { useAuth } from '../../../../hooks/useAuthentication'
import { useWorkspaceMemberInvitePost } from '../../../../hooks/useWorkspace'

export const WorkspaceSettingsMembersInvite = () => {
  const [isOpen, setIsOpen] = useState(false)
  const auth = useAuth()
  const create = useWorkspaceMemberInvitePost()
  const form = useForm<z.infer<typeof WorkspaceMemberInviteInput>>({
    resolver: zodResolver(WorkspaceMemberInviteInput),
    defaultValues: {
      emails: '',
    },
  })

  const onSubmit = (values: z.infer<typeof WorkspaceMemberInviteInput>) => {
    if (!auth.user?.currentWorkspaceId) {
      return
    }

    create.mutate(values.emails, {
      onSuccess: () => {
        toast.success('Invite created')
        setIsOpen(false)
      },
      onError: (error) => {
        toast.error(error.message)
      },
    })
  }
  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>
        <Button type="button" variant="default" size={'sm'}>
          Invite members
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Invite members</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="emails"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Emails</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="one@example.com, two@example.com"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    Add the email adresses of your co-workers.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit">Invite</Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
