import { UserPutInput } from '@boring.tools/schema'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  toast,
} from '@boring.tools/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import type { z } from 'zod'
import { useAuth, useUserUpdate } from '../../hooks/useAuthentication'

export const UserProfile = () => {
  const auth = useAuth()
  const update = useUserUpdate()
  const form = useForm<z.infer<typeof UserPutInput>>({
    resolver: zodResolver(UserPutInput),
    defaultValues: {
      name: auth.user?.name ?? '',
    },
  })

  const onSubmit = (values: z.infer<typeof UserPutInput>) => {
    update.mutate(values, {
      onSuccess: () => {
        toast.success('Profile updated')
      },
    })
  }

  useEffect(() => {
    form.reset({ name: auth.user?.name ?? '' })
  }, [auth.user, form])

  return (
    <div className="max-w-screen-md">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input placeholder="Rick Sanchez" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button type="submit">Save</Button>
        </form>
      </Form>
    </div>
  )
}
