import { Button } from '@boring.tools/ui'
import { Link } from '@tanstack/react-router'
import { CircleCheckIcon, MoveRightIcon } from 'lucide-react'

export const BillingSuccess = () => {
  return (
    <div className="w-full flex flex-col items-center mt-20">
      <div className="absolute top-10 left-10 opacity-30">
        <img src="/logo.svg" alt="Subscribe" />
        <span className="tracking-widest text-3xl font-light">
          boring.tools
        </span>
      </div>
      <div className="w-full max-w-screen-md">
        <CircleCheckIcon
          size={64}
          className="text-emerald-600 mb-3"
          strokeWidth={1}
        />
        <h1 className="text-4xl">Subscription activated</h1>
        <p className="text-lg text-muted-foreground max-w-md">
          Thank you for your trust. Your subscription has been activated. <br />
          <br />
          You can start now. If you need help, take a look at our{' '}
          <a href="https://docs.boring.tools" className="text-accent">
            documentation
          </a>
          . If you have any problems, you can reach us at{' '}
          <a href="mailto:support@boring.tools" className="text-accent">
            support@boring.tools
          </a>
          .
        </p>

        <div className="inline-flex flex-col my-10 relative">
          <Button type="button" asChild size={'lg'} className="group">
            <Link to="/" className="text-xl">
              Let's go
              <MoveRightIcon
                size={24}
                className="ml-3 group-hover:translate-x-2 transition"
              />
            </Link>
          </Button>
        </div>
      </div>
    </div>
  )
}
