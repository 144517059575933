import { env } from '@boring.tools/env'
import { Toaster } from '@boring.tools/ui'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Outlet } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { useAuth } from '../../hooks/useAuthentication'
import { BillingSubscribe } from '../Billing/Subscribe'
import { UserInitialProfile } from '../User/InitialProfile'
import { WorkspaceInitialCreate } from '../Workspace/InitialCreate'

export const LayoutAuthenticated = () => {
  const auth = useAuth()

  if (auth.user?.onboardingState === 'profile') {
    return <UserInitialProfile />
  }

  if (auth.user?.onboardingState === 'workspace') {
    return <WorkspaceInitialCreate />
  }

  if (auth.user?.onboardingState === 'billing') {
    return <BillingSubscribe />
  }

  return (
    <>
      <Outlet />
      <Toaster />
      {env.VITE_ENV === 'development' && (
        <>
          <ReactQueryDevtools initialIsOpen={false} />
          <TanStackRouterDevtools position="bottom-right" />
        </>
      )}
    </>
  )
}
