import { Button } from '@boring.tools/ui'
import { Link, createFileRoute, redirect } from '@tanstack/react-router'
import { AccessTokenColumns } from '../../components/AccessToken/Table/Columns'
import { DataTable } from '../../components/AccessToken/Table/DataTable'
import { Layout } from '../../components/Layout'
import { PageWrapper } from '../../components/PageWrapper'
import { useAccessTokenList } from '../../hooks/useAccessToken'

const Component = () => {
  const { data, isPending } = useAccessTokenList()

  return (
    <Layout>
      <PageWrapper
        breadcrumbs={[
          {
            name: 'Access tokens',
            to: '/access-token',
          },
        ]}
      >
        <div className="flex w-full gap-5 justify-between items-start md:items-center flex-col md:flex-row">
          <h1 className="text-2xl">Access Tokens</h1>

          <Button asChild>
            <Link to="/access-token/create">Generate new token</Link>
          </Button>
        </div>
        {data && !isPending && (
          <DataTable data={data} columns={AccessTokenColumns} />
        )}
      </PageWrapper>
    </Layout>
  )
}
export const Route = createFileRoute('/access-token/')({
  component: Component,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.user) {
      throw redirect({
        to: '/authentication',
        search: {
          redirect: location.href,
        },
      })
    }
  },
})
