import { AuthenticationEmailRequestInput } from '@boring.tools/schema'
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Separator,
  toast,
} from '@boring.tools/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link, useNavigate } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import type { z } from 'zod'
import {
  authWithGithub,
  useAuthenticationEmailRequest,
} from '../../hooks/useAuthentication'

export const AuthenticationSignin = () => {
  const emailRequest = useAuthenticationEmailRequest()
  const navigate = useNavigate()
  const form = useForm<z.infer<typeof AuthenticationEmailRequestInput>>({
    resolver: zodResolver(AuthenticationEmailRequestInput),
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = (
    values: z.infer<typeof AuthenticationEmailRequestInput>,
  ) => {
    emailRequest.mutate(values, {
      onSuccess() {
        navigate({ to: '/authentication', search: { email: values.email } })
      },
      onError(error) {
        toast.error(error.message)
      },
    })
  }

  return (
    <div>
      <Card className="max-w-md w-full mt-4">
        <CardHeader>
          <CardTitle className="text-xl">Authentication</CardTitle>
          <CardDescription>
            Enter your email below to sign in to your account or use one of the
            providers.
          </CardDescription>
        </CardHeader>

        <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>E-Mail</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="me@boring.tools"
                        autoFocus
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button className="w-full" type="submit">
                Sign in
              </Button>
            </form>
          </Form>
          <Separator className="my-7" />
          <Button
            className="w-full"
            variant={'outline'}
            onClick={authWithGithub}
          >
            Authenticate with GitHub
          </Button>
        </CardContent>
      </Card>

      <div className="flex gap-2 items-center justify-center mt-2">
        You dont't have an account yet?
        <Link to="/authentication/signup" className="text-accent">
          Sign up!
        </Link>
      </div>
    </div>
  )
}
