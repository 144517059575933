import { AuthenticationEmailSignupInput } from '@boring.tools/schema'
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Checkbox,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  toast,
} from '@boring.tools/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link, useNavigate } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import type { z } from 'zod'
import { useAuthenticationEmailSignup } from '../../hooks/useAuthentication'

export const AuthenticationSignup = () => {
  const signup = useAuthenticationEmailSignup()
  const navigate = useNavigate()
  const form = useForm<z.infer<typeof AuthenticationEmailSignupInput>>({
    resolver: zodResolver(AuthenticationEmailSignupInput),
  })

  const onSubmit = (values: z.infer<typeof AuthenticationEmailSignupInput>) => {
    signup.mutate(values, {
      onSuccess() {
        navigate({ to: '/authentication', search: { email: values.email } })
      },
      onError(error) {
        toast.error(error.message)
      },
    })
  }

  return (
    <div>
      <Card className="max-w-md w-full mt-4">
        <CardHeader>
          <CardTitle className="text-xl">Sign up</CardTitle>
          <CardDescription>
            We need a few details from you so that you can create an account.
          </CardDescription>
        </CardHeader>

        <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Rick Sanchez" {...field} autoFocus />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>E-Mail</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="me@boring.tools"
                        type="email"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="tos"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>I accept the terms of service</FormLabel>
                      <FormDescription>
                        You can find our terms of service{' '}
                        <Link href="/terms" className="text-accent">
                          here
                        </Link>{' '}
                        .
                      </FormDescription>
                    </div>
                  </FormItem>
                )}
              />
              <Button className="w-full" type="submit">
                Sign up
              </Button>
            </form>
          </Form>
        </CardContent>
      </Card>

      <div className="flex gap-2 items-center justify-center mt-2">
        You already have an account?
        <Link to="/authentication" className="text-accent">
          Sign in!
        </Link>
      </div>
    </div>
  )
}
