import { AuthenticationEmailCallbackInput } from '@boring.tools/schema'
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
  toast,
} from '@boring.tools/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import type { z } from 'zod'
import { useAuthenticationEmailCallback } from '../../hooks/useAuthentication'

export const AuthenticationOTP = ({ email }: { email: string }) => {
  const emailCallback = useAuthenticationEmailCallback()
  const form = useForm<z.infer<typeof AuthenticationEmailCallbackInput>>({
    resolver: zodResolver(AuthenticationEmailCallbackInput),
    defaultValues: {
      email,
      token: '',
    },
  })

  const onSubmit = (
    values: z.infer<typeof AuthenticationEmailCallbackInput>,
  ) => {
    emailCallback.mutate(values, {
      onSuccess() {
        window.location.replace('/')
      },
      onError(error) {
        toast.error(error.message)
      },
    })
  }

  return (
    <Card className="max-w-md w-full mt-4">
      <CardHeader>
        <CardTitle className="text-xl">Authentication</CardTitle>
        <CardDescription>
          Enter your OTP code below to sign in to your account.
        </CardDescription>
      </CardHeader>

      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="token"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>One-Time Password</FormLabel>
                  <FormControl>
                    <InputOTP
                      maxLength={8}
                      {...field}
                      onComplete={form.handleSubmit(onSubmit)}
                      autoFocus
                    >
                      <InputOTPGroup>
                        <InputOTPSlot index={0} />
                        <InputOTPSlot index={1} />
                        <InputOTPSlot index={2} />
                        <InputOTPSlot index={3} />
                      </InputOTPGroup>
                      <InputOTPSeparator />
                      <InputOTPGroup>
                        <InputOTPSlot index={4} />
                        <InputOTPSlot index={5} />
                        <InputOTPSlot index={6} />
                        <InputOTPSlot index={7} />
                      </InputOTPGroup>
                    </InputOTP>
                  </FormControl>
                  <FormDescription>
                    You find this code in your email we sent you to: {email}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              className="w-full"
              type="submit"
              disabled={emailCallback.isPending}
            >
              Sign in
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  )
}
