import { z } from '@hono/zod-openapi'

export const EmailInputBase = z
  .object({
    to: z.string().email().openapi({
      example: '',
    }),
    payload: z.unknown(),
  })
  .openapi('Email')

export const EmailOutputBase = z.object({
  to: z.string().email().openapi({
    example: '',
  }),
  subject: z.string().openapi({
    example: '',
  }),
  html: z.string().openapi({
    example: '',
  }),
})
