import { z } from '@hono/zod-openapi'

export const BillingStripeCustomerOutput = z
  .object({
    id: z.string().openapi({
      example: 'cus_NeGfPRiPKxeBi1',
    }),
    name: z.string().nullable(),
    email: z.string().email(),
  })
  .openapi('Stripe')

export const BillingStripeSessionInput = z
  .object({
    line_items: z.array(
      z.object({
        price: z.string(),
        quantity: z.number().optional(),
        adjustable_quantity: z
          .object({
            enabled: z.boolean(),
            minimum: z.number().optional(),
            maximum: z.number().optional(),
          })
          .optional(),
      }),
    ),
  })
  .openapi({
    example: {
      line_items: [
        {
          price: 'price_1QQQj0HfXLrT6x0wnUvURDRy',
          quantity: 1,
          adjustable_quantity: {
            enabled: true,
            minimum: 1,
            maximum: 10,
          },
        },
      ],
    },
  })

export const BillingStripeSessionOutput = z.object({
  url: z.string(),
})

export const BillingStripeSubscriptionOutput = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    price: z.number(),
    status: z.string(),
    currency: z.string(),
    interval: z.string(),
    quantity: z.number(),
  }),
)
